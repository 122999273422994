.timeline-data{
    position: relative;
    padding: 0 50px;
    margin-top: 30px;
}
.timeline-data:after{
    content: '';
    position: absolute;
    width: 4px;
    background-color: #EF4B36;
    opacity: 0.14;
    top: 0;
    bottom: 0;
    left: 50%;
    border-radius: 7px;
}
.sevice-box{
    position: relative;
    width: 50%;
}
.sevice-box:after{
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    right: -14px;
    background-color: #EF4B36;
    border-radius: 2px;
    transform: rotate(45deg);
    top: 42%;
    z-index: 1;
}
.sevice-box .left{
    left: 0;
}
.sevice-box .right{
    left: 100%;
}
.service-contain {
    padding: 30px 46px;
    position: relative;
}

.sevice-box .left .service-details{
    margin-left: 100px;
}
.sevice-box .right .service-details{
    margin-right: 100px;
}
.service-img{
    background: #EF4B36;
    box-shadow: 0px 3px 8px #00000029;
    border-radius: 12px;
    opacity: 1;
    float: left;
    width: 70px;
    height: 70px;
    padding: 18px 14px;
}
.service-box-title{
    font: normal normal 600 20px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 15px;
}
.service-box-contain{
    font: normal normal normal 14px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.44;
}
/* --------- work flow ---------- */
.work-row{
    padding-top: 40px;
}
.work-bg{
    background: url(../img/work-bg.png) no-repeat center;
    padding-top: 52px;
    padding-bottom: 56px;
}

.work-flow {
    margin-bottom: 41px;
    padding: 0 43px;
}

.work-flow:last-child{
    margin-bottom: 0;
}
.work-flow:nth-child(3){
    margin-bottom: 0;
}

.work-flow-title{
    text-align: left;
    font: normal normal 600 20px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin: 12px 0 10px;
}
.work-flow-contain{
    text-align: left;
    font: normal normal normal 14px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
}
/* ================================
    action row
=================================== */
.action-row{
    padding-top: 40px;
}
.mr-90{
    margin-right: 90px;
}
.blue-box{
    padding-bottom: 13.5px;
    border-bottom: 1px solid rgba(113, 66, 248,0.5);
}
.green-box{
    padding-bottom: 13.5px;
    border-bottom: 1px solid rgba(92, 189, 44,0.5);
}
.orange-box{
    padding-bottom: 13.5px;
    border-bottom: 1px solid rgba(243, 134, 80,0.5);
}
.yellow-box{
    padding-bottom: 13.5px;
    border-bottom: 1px solid rgba(236, 191, 36,0.5);
}
.pink-box{
    padding-bottom: 13.5px;
    border-bottom: 1px solid rgba(250, 76, 154,0.5);
}
.sky-box{
    padding-bottom: 13.5px;
    border-bottom: 1px solid rgba(53, 191, 231,0.5);
}
.action-box-title{
    text-align: left;
    font: normal normal 600 20px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 13px;
    margin-top: 14px;
}
.action-box-contain{
    text-align: left;
    font: normal normal normal 14px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
}
.action-box:last-child{
    margin-bottom: 0 !important;
}
.bg-blue{
    background-color: #7142F8;
    box-shadow: 5px 5px 16px #7142F83E;
}

.bg-green{
    background-color: #5CBD2C;
    box-shadow: 5px 5px 16px #5CBD2C3E;
}
.bg-orange{
    background-color: #F38650;
    box-shadow: 5px 5px 16px #F386503E;
}

.bg-yellow{
    background-color: #ECBF24;
    box-shadow: 5px 5px 16px #ECBF243E;
}

.bg-pink{
    background-color: #FA4C9A;
    box-shadow: 5px 5px 16px #FA4C9A3E;
}

.bg-sky{
    background-color: #35BFE7;
    box-shadow: 5px 5px 16px #35BFE73E;
}
.box{
    width: 54px;
    height: 54px;
    border-radius: 4px;
    padding: 12px; 
}
@media screen and (min-width:991px){
    .work-flow{
        padding: 0 43px;
    }
    .work-flow:nth-child(odd){
        padding-left: 15px;
    }
    .work-flow:nth-child(even){
        padding-right: 15px;
    }
}
@media screen and (max-width:991px){
    .work-flow:nth-child(3){
        margin-bottom: 41px;
    }
}
@media screen and (max-width:768px){
    .timeline-data{
        padding: 0;
    }
    .sevice-box{
        width: 100%;
    }
    .sevice-box:after{
        right: unset;
        left: 0px;
    }
    .sevice-box .right{
        left: 0;
    }
    .timeline-data:after{
        left: 10px;
    }
    .work-flow{
        padding: 0 15px;
    }
    .service-contain{
        padding: 30px 0px 15px 46px;
    }
    .mr-90{
        margin-right: 0;
    }
}
@media screen and (max-width:500px){
    .service-img{
        float: unset !important;
        margin-bottom: 20px;
    }
    .sevice-box .left .service-details{
        margin-left: 0;
    }
    .sevice-box .right .service-details{
        margin-right: 0;
        text-align: left !important;
    }
    
}