/* .our-wrok-list li .nav-link{
    text-align: left;
    font: normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: rgba(0, 0, 0,0.34);
    text-transform: capitalize;
} */
/* .nav-pills .nav-link.active{
    background-color: transparent;
    color: #EF4B36;
} */
.our-work-box{
    padding-bottom: 50px;
}
.our-work-portfolio{
    padding-top: 35px;
    padding-bottom: 50px;
}
.portfolio-img{
    width: 100%;
    height: 346px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.polynine-box{
    background: #B51647 0% 0% no-repeat padding-box;
}
.trucking-box{
    background: #EF4B36 0% 0% no-repeat padding-box;
}
.enliven-box{
    background: #7B68EE 0% 0% no-repeat padding-box;
}
.newkeyz-box{
    background: #35BFE7 0% 0% no-repeat padding-box;
}
.imeyou-box{
    background: #7142F8 0% 0% no-repeat padding-box;
}
.medixmd-box{
    background: #FA4C9A 0% 0% no-repeat padding-box;
}
.merti-box{
    background: #0D2D4D 0% 0% no-repeat padding-box;
}
.ixpress-box{
    background: #F38438 0% 0% no-repeat padding-box;
}
.india-box{
    background: #27AE60 0% 0% no-repeat padding-box;
}
.chat-box{
    background: #FFC907 0% 0% no-repeat padding-box;
}
.our-box-title{
    text-align: left;
    font: normal normal 600 40px/60px Poppins;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
.work-box-sub-title{
    text-align: left;
    font: normal normal 600 24px/33px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin:15px 0 8px;
}
.work-box-contain{
    text-align: left;
    font: normal normal  18px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.54;
}
.work-more-btn{
    padding-top: 30px;
}