
/* ================================
            what we do
=================================== */
.what-do-section .linetop {
    width: 98%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1;
    border-top: 2px dashed #000;
    animation: moveToRight linear 0.71s infinite;
}
/* ===== animation ===== */
@keyframes moveToRight {
    0% {
        transform: translateX(0px);
    } 
    100% {
        transform: translateX(10px);
    }
} 
.what-do-section .dots{
    position: relative;
}
.what-do-section .dots::after{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    left: 0;
    top: 38%;
    background: #EF4B36 0% 0% no-repeat padding-box;
    opacity: 1; 
}
.what-do-section .dots::before{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    right: 0px;
    z-index: 999;
    top: 38%;
    background: #EF4B36 0% 0% no-repeat padding-box;
    opacity: 1; 
}
.we-do-title{
    text-align: left;
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: #EF4B36;
    text-transform: capitalize;
    opacity: 1;
    padding: 15px 44px;
    background: #FDF0EE 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #00000024;
    border-radius: 27px;
    display: table;
    height: 54px;
    width: 200px;
    white-space: nowrap;
    margin: 0 auto;
}
.system-techno{
    padding-top: 60px;
}
.dev-content-data{
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 18px;
}
.dev-content-data:hover{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 20px;
}
 
.dev-content-data .dev-icon{
    background: #FDF0EE 0% 0% no-repeat padding-box;
    border-radius: 12px;
    display: inline-block;
    padding: 13px 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.dev-content-data .dev-icon .a{
    fill: #000;
    stroke: #000;
    stroke-width: 0.4px;
}
.dev-content-data:hover  .dev-icon{
    background: #EF4B36 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000029;
}
.dev-content-data:hover  .dev-icon .a{
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.dev-content-data:hover .a{
    fill: #fff;
    stroke: #fff;
    stroke-width: 0.4px;
}
.dev-content-data .dev-title{
    text-align: left;
    font: normal  600 20px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 13px 0 14px;
}
.dev-content-data .dev-contain{
    text-align: left;
    font: normal normal  14px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.54;
}
/* ================================
            archive ment
=================================== */
.archive-ment .common-title{
    color: #fff;
}
.archive-ment{
    background: #2A2B3B 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    padding-bottom: 77px;
}
.archive-right-data{
    margin-left: 21px;
}
.archive-ment-row{
    margin-top: 50px;
}
.archive-title{
    text-align: left;
    font: normal normal 20px/28px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding-bottom: 4px;
}
.archive-contain{
    text-align: left;
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.54;
}
/* ================================
            expertise
=================================== */
.expertise-section{
    background-color: #F5F5F5;
}

.expert-techno-title{
    font: normal normal 600 28px/38px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 18px;
}
.expert-techno-contain{
    font: normal normal normal 16px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.expertise-row{
    padding-top: 40px;
}
.all-technology{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 10px 21px;
    margin-bottom: 28px;
    border-left: 6px solid;
} 
.node-js{
    border-left: 6px solid #8ED32E;
} 
.techno-img{
    width: 40px;
    height: 40px;
}
.techno-common-name{
    text-align: left;
    font: normal normal  16px/26px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    padding-top: 2px;
    white-space: nowrap;
}
.techno-node{
    color: #8ED32E;
}
.techno-meteor{
    color: #FF7547;
}
.meteor-js{
    border-left: 6px solid #FF7547;
}
.techno-react{
    color: #61DAFB;
}
.react-js{
    border-left: 6px solid #61DAFB;
}
.techno-angular{
    color: #83C962;
}
.angular-js{
    border-left: 6px solid #83C962;   
}
.techno-aws{
    color: #F8B10A;
}
.aws-js{
    border-left: 6px solid #F8B10A;    
}
.mongo-js{
    border-left: 6px solid #10AA50;
}
.techno-mongo{
    color: #10AA50;
}
.android-js{
    border-left: 6px solid #AAC148;
}
.techno-android {
    color:  #AAC148;
}
.ios-js{
    border-left: 6px solid #0A0A0A;
}
.techno-ios{
    color: #0A0A0A;
}
.graph-js{
    border-left: 6px solid #E10098; 
}
.techno-graph{
    color: #E10098;
}
.graphql-js{
    border-left: 6px solid #0F3153;
}
.techno-graphql{
    color: #0F3153;
}
.laravel-js{
    border-left: 6px solid#FF2D20;
}
.techno-laravel{
    color: #FF2D20;
}
.sql-js{
    border-left: 6px solid #006B97;
}
.techno-sql{
    color: #006B97;
}
/* ================================
            slider
=================================== */
.slider-row{
    padding-top: 55px;
}
.slider-box {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 0px 20px 20px 0px;
    opacity: 1;
    background-color: #fff;
    z-index: 99999;
    min-height: 500px;
}
.dotted-top img{
    position: absolute;
    top: -81px;
    z-index: -1;
}
.dotted-bottom img{
    position: absolute;
    right: -85px;
    bottom: -105px;
    z-index: -1;
}
img.quote-icon {
    position: relative;
    left: 15%;
    margin-bottom: 9px;
}
.client-img img{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    max-width: unset;
    margin-right: 34px;
}
.client-review-title{
    text-align: left;
    font: normal normal 600 26px/36px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 2px;
}
.client-review-subtitle{
    text-align: left;
    font: normal normal medium 18px/68px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 13px;
}
.client-review-contain{
    text-align: left;
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.74;
}
.client-img-vertical img{
    width: 40px;
    height: 40px;
    margin: 0 auto;
    border-radius: 50%;
}
.client-img-data .slick-prev{
    font-size: 0;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 46%;
    bottom: 80px;
    z-index: 1;
}
.client-img-data .slick-next{
    text-align: left;
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 39%;
    bottom: 50px;
    transform: rotate(90deg);
}
.client-img-data .slick-prev::before{
    content: '';
    position: absolute;
    width: 13px;
    height: 28px;
    right: 0px;
    bottom: 0;
    background-repeat: no-repeat;
    background-image: url(../img/long-arrow-up.svg);
}
.client-img-data .slick-next::before{
    content: '';
    position: absolute;
    width: 13px;
    height: 28px;
    right: -19px;
    transform: rotate(270deg);
    top: -2px;
    background-repeat: no-repeat;
    background-image: url(../img/long-arrowdown.svg);
}
.client-img-data{
    position: unset !important;
}
.client-img-vertical{
    margin-bottom: 31px;
}
.client-img-data .slick-center {
    transform: scale(1.5);
    opacity: 1 !important;
}
/* .client-img-data .slick-center img{
    margin-top: 15px;
} */
.client-img-data .slick-slide{
    opacity: 0.5;
}
.country-client{
    background-image: url(../img/bg-boder.png);
    background-position: center left;
    background-repeat: no-repeat;
    background-position-x: -30%;
    /* animation: rotation 8s infinite linear; */
    position: relative;
    /* overflow-x: hidden; */
}
.animation-icon{
    position: absolute;
    top:11%;
    z-index: -1;
    display: none;
    left: 0;
    /* animation: rotation 8s infinite linear; */
}
/* @keyframes rotation{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
} */
.country-title{
    font: normal normal 600 22px/25px Poppins;
    color: #000;
}
.expand-work{
    text-align: center;
    font: normal normal 12px/17px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
}
.country-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    margin-left: 260px;
    width: 150px;
    height: 150px;
    padding: 10px 15px;
    text-align: center;
}
.country-box:first-child{
    margin-bottom: 98px;
}
.country-box:last-child{
    margin-top: 78px;
}
.country-red-box{
    background: #EF4B36 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    width: 150px;
    height: 150px;
    padding: 10px 15px;
    text-align: center;
}
.country-red-title{
    font: normal normal 600 22px/25px Poppins;
    color: #fff;
}
.expand-red-work{
    text-align: center;
    font: normal normal 12px/17px Poppins;
    letter-spacing: 0px;
    color: #fff;
}

/* =====================================
            trusted
========================================= */
.trusted-company{
    padding: 30px 0 60px;
}
.trusted-slider .slick-arrow{
    font-size: 0;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    right: 2%;
    bottom: -30px;
}
.trusted-slider .slick-prev{
    right: 5%;
}
.trusted-slider .slick-prev:before{
    content: '';
    position: absolute;
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/prev.svg);
}
.trusted-slider .slick-next:before{
    content: '';
    position: absolute;
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/next.svg);
}
/* ================================
            footer
=================================== */
footer{
    background-color: #2A2B3B;
    padding: 40px 0 24px !important;
}
footer .common-title{
    color: #fff;
}
.menu-list li{
    padding-bottom: 22px;
}
.footer-menu-title{
    font: normal normal  18px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 27px;
}
.footer-menu{
    text-align: left;
    font: normal normal normal 16px/23px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
}
.footer-menu:hover {
    opacity: 1;
    color: #fff;
}
.menu-row{
    padding-top: 57px;
    padding-bottom: 45px;
}
.footer-icon{
    width: 44px;
    height: 44px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #40414F;
}
.footer-address-title{
    text-align: left;
    font: normal normal  16px/23px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    padding: 5px 0 8px;
}
.address-contain{
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    /* text-transform: capitalize; */
    opacity: 0.5;
}
.career-sales{
    padding-top: 20px;
}
.news-letter-data{
    padding-bottom: 18px;
}
.news-letter-row{
    padding-top: 20px;
}
.email-send-input{
    font: normal normal normal 13px/19px Poppins;
    letter-spacing: 0px;
    color: #A0A0A7;
    border: 0;
    border-radius: 27px;
    height: 54px;
    padding:0 55px 0 28px;
    width: 100%;
    background-color: #40414F;
}
.email-icon{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    top: 7px;
    right: 23px;
}
.copy-right-text{
    font: normal normal normal 18px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
    text-align: center;
}
.share-icon li{
    padding: 0 11px;
}
.share-icon li:first-child{
    padding-left: 0;
}
.share-icon li:last-child{
    padding-right: 0;
}
/* =====================================
            responsive
========================================= */
@media screen and (max-width:1350px){
    .country-box{
        margin-left: auto;
    }
}
@media screen and (max-width:1200px){
    .client-img-data .slick-prev{
        right: 47%;
    }
}
@media screen and (max-width:1140px){
    .client-img-data .slick-next{
        right: 38%;
    }
}
@media screen and (max-width:991px){
    .client-img-data .slick-next{
        right: 7%;
        transform: rotate(360deg);
    }
    .client-img-data .slick-prev::before{
        transform: rotate(270deg);
    }
    .client-img-data .slick-prev{
        right: 12%;
        bottom: 0px;
    }
    .client-img-data .slick-next{
        bottom: 3px;
    }
    .client-img-data .slick-center{
        transform: unset;
    }
    .client-img-data .slick-center img{
        width: 60px;
        height: 60px;
    }
    .trusted-slider .slick-prev{
        right: 7%;
    }
    .trusted-slider .slick-next{
        right: 3%;
    }
    .customer-slider{
        padding-left: 0;
    }
    .client-img img{
        margin-right: 0;
    }
    .dotted-bottom img{
        right: 13px;
    }
    .dotted-top img{
        top: -51px;
    }
    .archive-ment-row{
        margin-top: 30px;
    }
    .expertise-row,
    .thought-title-contain{
        padding-top: 15px;
    }
}
@media screen and (max-width:840px){
    .client-img-data .slick-prev{
        right: 14%;
    }
    .get-quote{
        padding: 25px 20px 25px 28px;
    }
}
@media screen and (max-width:767px){
    .client-img-data .slick-prev{
        right: 15%;
    }
    .trusted-slider .slick-prev{
        right: 8%;
    }
    .client-img-data .slick-prev::before{
        right: 2px;
    }
    .footer-address-title{
        font: normal normal  15px/23px Poppins;
    }
}
/* @media screen and (max-width:641px){
    .quote-btn{
        margin-top: 30px;
    }
} */
@media screen and (max-width:620px){
    .client-img-data .slick-prev{
        right: 15%;
    }
    .trusted-slider .slick-prev{
        right: 12%;
    }
    .trusted-slider .slick-next{
        right: 5%;
    }
    
}
@media screen and (max-width:575px){
    .client-img-data .slick-prev{
        bottom: 0;
        right: 0;
        left: 76.5%;
    }
    /* .client-img-data .slick-next{
        bottom: 1px;
    } */
    .trusted-slider .slick-prev{
        right: 15%;
    }
    .client-img-data .slick-next{
        right: 14%;
    }
    .client-img-data .slick-next::before{
        top: -3px;
    }
}
@media screen and (max-width:500px){
    .client-img-data .slick-prev{
        left: 73.5%;
    }
}
@media screen and  (max-width:380px){
    .client-img-data .slick-prev{
        left: 71.5%;
    }
}
@media screen and  (max-width:340px){
    .client-img-data .slick-prev{
        left: 69.5%;
    }
}

.contactError{
    color: red;
    font-size: 10px;
    padding-left: 16px;
}