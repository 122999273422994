.join-us{
    font: normal normal  18px/32px Poppins;
    letter-spacing: 0px;
    color: #EC4C3D;
}
.champion-row-contain{
    margin-bottom: 72px;
}
.champiom-row{
    padding-top: 43px;
}
.champion-row2{
    margin-top: 40px;
}
.champiom-data:nth-child(even){
    margin-top: 70px;
}
.champion-img{
    position: relative;
    margin-bottom: 50px;
}
.mask-group{
    display: block;
    margin: 0 auto;
}
.line-img{
    position: absolute;
    left: 50%;
    top: 98%;
}
.champion-bio{
    border-radius: 4px;
    max-width: 200px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
}
.champion-border{
    position: relative;
    display: none;
}
.champion-border:after{
    content: '';
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 1;
    width: 53px;
    background: #fff;
    height: 2px;
}
.champion-right-angle:after{
    content: '';
    position: absolute;
    bottom: 8px;
    right: 10px;
    z-index: 1;
    width: 53px;
    background: #fff;
    height: 2px;
}
.champion-right-angle:before{
    content: '';
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    width: 2px;
    background: #fff;
    height: 45px;
}
.champion-right-angle {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
}
.champion-border:before{
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    width: 2px;
    background: #fff;
    height: 45px;
}
.champion-detail{
    background: #EF4B36;
    border-radius: 4px;
    position: absolute;
    left: 20px;
    right: 20px;
    z-index: 1;
    padding: 5px 00;
    top: 90%;
    transition: .2s ease-in-out;
}
.champion-bio::after{
    content: '';
    position: absolute;
    top: unset;
    left: 0;
    border-radius: 4px;
    right: 0;
    bottom: 0;
    background-color:#0000007a;
    
}
.champion-name{
    text-align: center;
    font: normal normal 16px/23px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
}
.champion-position{
    text-align: center;
    font: normal normal normal 12px/17px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 0.7;
}
.champion-col{
    width: 20%;
    padding: 0 4px;
    margin-bottom: 75px;
    /* transition: 2s ease; */
}
.champion-detail::after{
    content: '';
    position: absolute;
    border-left: 3px solid #fff;
    top: 0;
    left: 0;
}
.champion-col:hover .champion-bio{
    top: 0;
    bottom: 0;
}
.champion-col:hover .champion-bio::after{
    top: 0;
}
.champion-col:hover .champion-detail{
    top: calc(50% - 50px/2);
    /* height: 50px; */
}
.champion-col:hover .champion-border,
.champion-col:hover .champion-right-angle{
    display: block;
}
/* =========== modal detail =========== */
.champion-dialog{
    max-width: 912px !important;
    width: 100%;
    border-radius: 8px;
    margin: 0 auto;
    min-height: calc(100% - (1.75rem * 2));
    align-items: center;
    display: flex;
}
.employee-modal{
    padding-left: 15px;
}
.employee-modal .modal-content{
    border-radius: 9px;
}
.employee-modal .modal-body{
    padding: 30px 40px 30px 52px;
    border-left: 12px solid #EF4B36;
    border-radius: 8px 0px 0px 8px;
}
.modal-detail-title{
    font: normal 600 24px/33px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.modal-detail-subtitle{
    font:  normal 500 18px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 8px;
}
.modal-detail-contain{
    font:  normal 16px/32px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.6;
}
.employee-full-detail{
    padding-left: 40px;
}
.close-btn{
    position: absolute;
    right: 18px;
    top: 18px;
}
/* =========== Responsive ============= */
@media screen and (max-width:991px){
    .champion-col{
        width: 25%;
    }
}
@media screen and (max-width:768px){
    .champion-col{
        width: 33.33%;
    }
    .employee-full-detail{
        padding-left: 0;
    }
    .employee-modal .modal-body{
        padding: 30px;
    }
    .champion-dialog{
        max-width: 500px !important;
    }
}
@media screen and (max-width:620px){
    .champion-col{
        width: 50%;
    }
}
@media screen and (max-width:400px){
    .champion-col{
        width: 100%;
    }
}