
/* .nav-pills .nav-link.active{
    background-color: transparent;
    color: #EF4B36;
} */
.our-work-box{
    padding-bottom: 50px;
}
.our-work-portfolio{
    padding-top: 35px;
    padding-bottom: 50px;
}

.blog {
	width: 100%;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000029;
	border-radius: 4px;
}
.blog-details {
	padding: 12px 15px 20px;
}
.blog-img {
	width: 100%;
	height: 250px;
	background-color: #EF4B36;
	border-radius: 4px 4px 0 0;
}
.blog-text {
	text-align: left;
	font: normal normal 600 16px/24px Poppins;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
    margin-bottom: 15px;
}
.day {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.days {
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #000000;
	text-transform: uppercase;
	opacity: 0.5;
	float: left;
	margin: 0;

}
.read {
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #000000;
	text-transform: uppercase;
	opacity: 0.5;
	float: right;
	margin: 0;

}
.btn-red {
	padding: 12px 35px;
	color: #ffffff;
	background: #EF4B36 0% 0% no-repeat padding-box;
	box-shadow: 0px 6px 8px #EF4B3629;
	border-radius: 4px;
	text-align: left;
	font: normal normal medium 16px/25px Poppins;
	letter-spacing: 0.16px;
	opacity: 1;
}