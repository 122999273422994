.contact-form-data .common-title-contain{
    color: #000;
}
.company-loaction{
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000029;
}
.loaction-detail{
    border-bottom: 2px solid #FBD9D4;
}
.loaction-detail img{
    display: block;
    margin: 0 auto;
}
.loaction-address{
    text-align: left;
    font: normal normal normal 20px/38px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
    padding: 10px 0 34px;   
}
.email-title{
    text-align: center;
    font: normal normal 600 20px/38px Poppins;
    letter-spacing: 0px;
    color: #EF4B36;
    padding-top: 28px;
}
.email-address{
    text-align: center;
    font: normal normal normal 20px/38px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: lowercase;
    opacity: 1;
}
.contact-form-row{
    padding-top: 40px;
}
.contact-from{
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 10px;
    padding: 40px 38px;
}
.contact-from .input-form{
    padding-bottom: 22px;
}
.contact-from .input-form:last-of-type{
    padding-bottom: 40px;
}
/* .contact-input{
    height: 60px;
    text-align: left;
    letter-spacing: 0px;
    color: #9C9696;
    padding: 16px;
    width: 100%;
    border-radius: 6px;
    border: 0;
    background-color: #FEF4F3;
} */
/* .contact-text-area{
    text-align: left;
    letter-spacing: 0px;
    color: #9C9696;
    padding: 16px;
    width: 100%;
    border-radius: 6px;
    border: 0;
    background-color: #FEF4F3;
} */
.form-submit{
    text-align: left;
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    background: #EF4B36;
    border-radius: 4px;
    padding: 13px 42px;
    border: 0;
    cursor: pointer;
    margin: 0 auto;
    display: block;
}