/* .our-wrok-list li .nav-link{
    text-align: left;
    font: normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: rgba(0, 0, 0,0.34);
    text-transform: capitalize;
} */
/* .nav-pills .nav-link.active{
    background-color: transparent;
    color: #EF4B36;
} */
.our-work-box{
    padding-bottom: 50px;
}
.our-work-portfolio{
    padding-top: 10px;
    padding-bottom: 50px;
}