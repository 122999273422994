/* ================================
            banner
=================================== */
.play-btn{
    width: 46px;
    height: 46px;
    background-color: #EF4B36;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 12px #ec2e15f7;
}
.play-btn i{
    font-size: 16px;
    color: #fff;
}
.watch-video{
    text-align: left;
    font: normal normal 600 16px/22px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
    width: 207px;
    margin-left: 20px;
}
.video-modal .modal-dialog{
    max-width: 900px;
    /* width: 100%; */
}
.video-modal .modal-content{
    background-color: transparent;
    border: 0;
}
.video-modal .modal-body{
    padding: 0;
}
/* ================================
        Who We Data
=================================== */
.who-we-data{
    padding: 0 45px;
}
/* ================================
        Founder And CTo
=================================== */
/* .ceo-row{
    padding-top: 40px;
} */
.ceo-contain .ceo-name{
    text-align: left;
    font: normal normal 600 30px/46px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 6px;
    opacity: 1;
}
.ceo-contain .ceo-position{
    text-align: left;
    font: normal normal 600 22px/33px Poppins;
    letter-spacing: 0px;
    color: #EF4B36;
    margin-bottom: 22px;
    opacity: 1;
}
.ceo-contain .company-since{
    text-align: left;
    font: normal normal normal 16px/32px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    padding-bottom: 15px;
}
.meet-team,
.join-team{
    font: normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    padding: 15px 17px;
    border-radius: 4px;
    display: inline-block;
    border: 1px solid #EF4B36;
    background: #EF4B36 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 8px #EF4B3629;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.meet-team:hover{
    background-color: #fff;
    color: #EF4B36;
    border: 1px solid #EF4B36;
}
