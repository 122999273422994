.all-opening{
    text-align: left;
    font: normal normal bold 16px/25px Poppins;
    letter-spacing: 0.8px;
    color: #EF4B36;
    padding-bottom: 20.5px;
    text-transform: uppercase;
}
.role-title{
    text-align: left;
    font: normal normal 600 36px/55px Poppins;
    letter-spacing: 0px;
    color: #000000;
    position: relative;
}
.role-data{
    padding-bottom: 50px;
}
.role-data:last-child{
    padding-bottom: 0;
}
.role-title:after{
    content: '';
    position: absolute;
    width: 80px;
    height: 5px;
    left: 0;
    bottom: -2px;
    background: #EF4B36 0% 0% no-repeat padding-box;
    border-radius: 8px;
}
.role-contain{
    text-align: left;
    font: normal normal normal 18px/32px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    padding-top: 22px;
}
/* .contact-from{
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 10px;
    padding: 40px 38px;
} */
/* .contact-from .input-form{
    padding-bottom: 22px;
} */
/* .contact-from .input-form:last-of-type{
    padding-bottom: 40px;
} */
.devloper-row{
    padding-top: 60px;
}
/* .contact-input,
.contact-select{
    height: 60px;
    text-align: left;
    letter-spacing: 0px;
    color: #9C9696;
    padding: 16px;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    border-radius: 6px;
    border: 0;
    background-color: #FEF4F3;
} */
.contact-select{
    color: #797777;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;   
}
.select-form::after{
    content: '\f107';
    position: absolute;
    right: 25px;
    font-size: 24px;
    top: 25%;
    color: #F8A89D;
    font-family: 'fontawesome';
}
/* .contact-text-area{
    text-align: left;
    letter-spacing: 0px;
    color: #9C9696;
    padding: 16px;
    width: 100%;
    border-radius: 6px;
    border: 0;
    background-color: #FEF4F3;
} */
/* .form-submit{
    text-align: left;
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    background: #EF4B36;
    border-radius: 4px;
    padding: 13px 42px;
    border: 0;
    cursor: pointer;
    margin: 0 auto;
    display: block;
} */
.choose-file{
    text-align: left;
    font: normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background-color: #EF4B36;
    padding: 20px 35px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
}
.choose-file-text{
    text-align: center;
    font: normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    position: absolute;
    top: 20px;
    right: 25%;
    margin: 0 auto;
}
