.event {
	width: 100%;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000029;
	border-radius: 4px;

}
.event-details {
	padding: 12px 40px 0px 10px;
}
.event-img {
	width: 100%;
	height: 250px;
	background-color: #EF4B36;
	border-radius: 4px 4px 0 0;
}
.event-title {
	font-size: 40px;
	font-weight: 600;
	padding-top: 100px;
	text-align: center;
}
.event-text {
	text-align: left;
	font: normal normal 600 18px/27px Poppins;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;

}
.text-muted {
	text-align: left;
	font: normal normal normal 14px/30px Poppins;
	letter-spacing: 0px;
	color: #000000;
	opacity: 0.5;
	padding-bottom: 23px;
	margin: 0;
}
.event-date {
	text-align: right;
    font: normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
	padding: 0 10px 10px 0;
}
.page-link {
	color: var(--primary-black);
	text-align: left;
	font: normal normal 600 20px/30px Poppins;
	letter-spacing: 6.4px;
	color: #000000;
	opacity: 1;
	border: none;
}
.page-link:hover {
	color: #EF4B36;
	text-decoration: none;
	background-color: #ffffff;
}



.pagination {
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
  flex-wrap: wrap;
	float: right;
	cursor: pointer;
}
.pagination > .active > a {
	color: var(--primary-black);
	text-align: left;
	font: normal normal 600 20px/30px Poppins;
	letter-spacing: 6.4px;
	color: #000000;
	opacity: 1;
	border: none;
	cursor: pointer;
}
.pagination > li > a {
	color: #000000;
	text-decoration: none;
	background-color: #ffffff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	color: #EF4B36;
	text-decoration: none;
	background-color: #ffffff;
}
.pagination > li > a,
.pagination > li > span {
	color: var(--primary-black);
	text-align: left;
	font: normal normal 600 20px/30px Poppins;
	letter-spacing: 6.4px;
	color: #000000;
	opacity: 1;
	border: none;
	padding: 8px 10px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
