/* ================================
    trucking-banner
=================================== */
.client-platform{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.client-platform-title{
    text-align: left;
    font: normal normal 600 18px/25px Poppins;
    letter-spacing: 1.44px;
    color: #EF4B36;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 8px;
}
.client-platform-contain{
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.portfolio-img{
    width: 100%;
    height: 346px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EF4B36 0% 0% no-repeat padding-box;
}
.our-box-title{
    text-align: left;
    font: normal normal 600 40px/60px Poppins;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
/* ================================
    sub-banner
=================================== */
.hours-row{
    padding-top: 30px;
}
.hours-col:last-child{
    margin-bottom: 0;
}
.sub-banner-title{
    text-align: left;
    font: normal normal 600 36px/55px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.hours-img{
    padding: 10px;
    border-radius: 4px;
}
.blue-img{
    background: #7142F8;
} 
.red-img{
    background: #EF4B36; 
}  
.yellow-img{
    background: #ECBF24
}
.hours-title{
    text-align: left;
    font: normal normal 600 20px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin:14px 0 13px;
}
.hours-contain{
    text-align: left;
    font: normal normal normal 14px/23px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
}
/* ================================
    challenge task
=================================== */
.challange-title{
    font: normal normal 600 36px/51px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.challange-contain{
    text-align: left;
    font: normal normal 18px/32px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    padding: 20px 0;
}
.challenge-task-box{
    padding: 18px 25px;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 8px;
    margin-bottom: 28px;
}
.blue-line{
    border-left: 7px solid #7B68EE;
}
.blue-title{
    color: #7B68EE;
}
.pink-line{
    border-left: 7px solid #FD71AF;
}
.pink-title{
    color: #FD71AF;
}
.yellow-line{
    border-left: 7px solid #FFC800;
}
.yellow-title{
    color: #FFC800;
}
.challenge-task-title{
    text-align: left;
    font: normal normal bold 24px/33px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}
.challenge-task-contain{
    text-align: left;
    font: normal normal 600 22px/31px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 20px;
}
.scroll-list{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
}
.spy-left-option{
    transform: rotate(-90deg);
    position: absolute;
    left: -24%;
    border-bottom: 1px solid #ccc;
}
.list-group-item{
    text-align: left;
    font: normal normal  16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 0.34;
    border: 0;
    padding: 5px 35px;
    width: auto;
}
.list-group-item-action.active{
    z-index: 2;
    color: #EF4B36;
    background-color: transparent;
    border: 0;border-radius: 0;
    opacity: 1;
    border-bottom: 3px solid #EF4B36;
}

.scroll-list li{
    padding: 0 35px;
}
.scroll-list .scroll-tag{
    font: normal normal medium 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 0.34;
}
.scrollspy-example{
    height: 50vh;
    position: relative;
    overflow-y: scroll;
    padding-bottom: 15px;
}
.scrollspy-example::-webkit-scrollbar{
    display: none;
}
@media screen and (max-width:1320px){
    .spy-left-option{
        left: -21%;
    }
}
@media screen and (max-width:1250px){
    .list-group-item{
        padding: 5px 30px;
    }
}
@media screen and (max-width:1120px){
    .list-group-item{
        padding: 5px 20px;
    }
}
@media screen and (max-width:991px){
    .spy-left-option{
        transform: unset;
        position: inherit;
        left: 0;
    }
}
@media screen and (max-width:767px){
    .list-group-item{
        padding: 5px 15px;
    }
    .hours-title{
        font: normal normal 600 17px/20px Poppins;
        margin: 14px 0 5px;
    }
}