.conculation-title{
    text-align: left;
    font: normal normal 600 30px/43px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin: 0;
    opacity: 1;
}
.concultion-sub-title{
    text-align: center;
    font: normal normal medium 16px/23px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin: 37px 0 15px;
    text-transform: uppercase;
    opacity: 1;
}
.concultion-img{
    min-height: 450px;
    height: 100%;
    width: 100%;
    /* background-color: #EF4B361A; */
}
.concultion-common-contain{
    text-align: left;
    font: normal normal normal 18px/40px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    padding: 10px 0 15px;
}