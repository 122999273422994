/* ----- reset css ----- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
    --unnamed-color-ffffff: #FFFFFF;
    --secondary-red: #EF4B36;
    --primary-black: #000000;
}
*{
    font-family: 'Poppins', sans-serif !important;
}
a{
    text-decoration: none !important;
}
button:focus{
    outline: none;
}
img{
    max-width: 100%;
}
input:focus,
select:focus,
textPath:focus-visible{
    outline: none;
}
a:focus{
    box-shadow: none !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
/* =====================================
            common css
======================================== */
section{
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 40px 0 70px;
}
.container-fluid{
    max-width: 1366px;
}
.container{
    max-width: 1270px;
}
.contact-container{
    padding: 0 80px;
}
.common-title{
    text-align: center;
    font: normal  600 40px/56px Poppins;
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
}
.common-title-contain{
    text-align: center;
    font:  normal 18px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
    padding-top: 6px;
}
.common-btn{
    background: #EF4B36 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #ED422F66;
    border-radius: 4px;
    opacity: 1;
    text-align: left;
    font: normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    padding: 10.8px 28px;
    border: 1px solid #EF4B36;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.common-btn:hover{
    background-color: #fff;
    color: #EF4B36;
    border: 1px solid #EF4B36;
}
.cursor-pointer{
    cursor: pointer;
}
.navbar-light .navbar-toggler{
    border-color: transparent;
}
/* =====================================
            header
======================================== */
.header{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    opacity: 1;
    padding: 19px 8px;
}
.header .navbar-collapse{
    flex-grow: unset;
}
.header .navbar .collapse .nav-item .nav-link{
    color: var(--primary-black);
    text-align: left;
    font: normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 0.44;
    padding: 9px 16px;
    cursor: pointer;
}
.header .navbar .collapse .nav-item{
    margin-right: 7px;
    margin-left: 7px;
    position: relative;
}
.header .navbar .collapse .nav-item:last-child{
    margin-right: 0;
}
.header .navbar .collapse .nav-item:first-child{
    margin-left: 0;
}
.header .navbar .collapse .nav-item .nav-link:hover{
    background-color: #EF4B36;
    color: #fff;
    opacity: 1;
    border-radius: 4px;
}
.header .navbar .collapse .nav-item .active{
    background-color: #EF4B36;
    opacity: 1;
    color: #fff;
    border-radius: 4px;
}
/* .header .navbar .collapse .nav-item.active a{
    color: #fff;
    opacity: 1;
} */
/* ====== sub Menu ===== */
.sub-menu{
    box-shadow: 0px 0px 10px #00000029;
    border-top: 6px solid #EF4B36;
    border-radius: 5px;
    /* position: relative; */
    top: 150%;
}
/* .triangle-up {
	width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #EF4B36;
    position: absolute;
    top: -18px;
    right: 6px;
} */
.sub-menu li{
    text-align: center;
    border-bottom: 1px solid #0000001f;
}
.sub-menu li a{
    color: var(--primary-black);
    font: normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 0.44;
    width: 100%;
    padding: 9px 20px;
    display: inline-block;
}
.sub-menu li a:hover{
    background-color: #EF4B36;
    color: #fff;
    opacity: 1;
}
.nav-item .dropdown-toggle::after{
    margin-left: 20px;
}
.filter-list1{
    display: none;
    border: 1px solid #000;
    border-radius: 0;
    position: absolute;
    background: #fff;
    left: 0;
    top: 75px;
    right: 0;
    z-index: 1;
    box-shadow: 1px 0px 2px 0px rgba(104, 78, 78, 0.2);
}
.list-option{
    font-size: 15px;
    color: #2E2E38;
    padding: 10px;
    width: 100%;
    font-weight: 300;
    display: inline-block;
}
.list-option:hover{
    color: #2E2E38;
}
/* ================================
            banner
=================================== */
.banner-title{
    text-align: left;
    font: normal 600 36px/55px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
 .banner-contain{
    text-align: left;
    font: normal normal 18px/32px Poppins;
    letter-spacing: 0px;
    color: #00000080;
    /* opacity: 0.5; */
    margin: 27.5px 0 28px;
}
/* ================================
            Thought
=================================== */
.thought-title-contain{
    color: #000000 ;
    opacity: 0.54 ;
    font: normal normal normal 20px/36px Poppins;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 30px;
}
.opinions-row{
    padding-top: 40px;
}
.opinions-box{
    padding: 22px 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 8px;
    opacity: 1;
    border-left: 10px solid;
}
.opinions-box1{
    border-left: 10px solid #757575;
}
.opinions-box2{
    border-left: 10px solid #F69E92;
}
.opinions-box .opinions-box-title{
    text-align: left;
    font: normal normal 600 20px/36px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.opinions-box .opinions-box-contain{
    text-align: left;
    font: normal normal normal 14px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.44;
    padding: 30px 0;
}
.opinions-box .read-more{
    text-align: left;
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
}
/* ================================
            get-quote
=================================== */
.get-quote{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #00000029;
    border: 1px solid #0000000D;
    border-radius: 8px;
    opacity: 1;
    padding: 38px 63px 38px 37px;
    border-left: 12px solid #EF4B36;
}
.quote-title{
    text-align: left;
    font: normal normal 600 26px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.quote-btn{
    text-align: left;
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    background: #EF4B36;
    border-radius: 4px;
    opacity: 1;
    display: inline-block;
    padding: 10.5px 17px;
    border: 1px solid #EF4B36;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.quote-btn:hover{
    border: 1px solid #EF4B36;
    background-color: #fff;
    color: #EF4B36;
}
/* ================================
            simply work flow
=================================== */
.simply-work-flow{
    background-color: #2A2B3B;
    padding: 71px 0;
}
.simply-work-flow .quote-icon {
    width: 154px;
    height: 154px;
    border-radius: 50%;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}
.simply-work-flow .work-title,
.scaleteam-sub-title{
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0.29px;
    color: #EF4B36;
    text-transform: uppercase;
}
.simply-work-flow .work-contain{
    text-align: left;
    font: normal normal 600 46px/69px Poppins;
    letter-spacing: 0.83px;
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
}
.join-our-team{
    margin-top: 32px;
}
.pioneer-row{
    margin-top: 41px;
}
.pionner-img{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin: 10px 0px ;
}
.pionner-img img{
    object-fit: cover;
    /* height: 100%; */
    box-shadow: 0px 0px 6px #00000029;
}
.pionner-imgs{
    height: 100%;
}
/* ================================
            our vision
=================================== */
.vision-row{
    padding-top: 40px;
}
.our-vision-section .vision-box{
    background-color: #FFFFFF;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 8px;
    padding: 20px 28px 50px 27px;
}
.our-vision-section .vision-box:nth-child(2){
    padding-bottom: 110px;
}
.our-vision-section .vision-title{
    text-align: left;
    font: normal normal 600 18px/25px Poppins;
    letter-spacing: 0.47px;
    text-transform: uppercase;
    padding-bottom: 15px;
    opacity: 1;
    color: #FD71AF;
}
.our-vision-section .vision-box-title{
    font-size: 29px;
    font-weight: 600;
    color: #EF4B36;
    padding-right: 18px;
    padding-top: 6px;
}
.our-mission{
    background-image: url('../img/XMLID_996_.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
}
.our-vision{
    background-image: url('../img/vision.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
}
.core-value{
    background-image: url('../img/reliability.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
}
.our-vision-section .vision-contain{
    text-align: left;
    font: normal normal normal 16px/29px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
    padding-right: 65px;
}
.our-vision-section .our-mission{
    border-left: 7px solid #EF4B36;
}
.our-vision-section .our-mission .vision-title{
    color: #EF4B36;
}
.our-vision-section .our-vision{
    margin-top: 40px;
}
.our-vision-section .our-vision{
    border-left: 7px solid #FD71AF;
}
.our-vision-section .core-value{
    border-left: 7px solid #EF4B36;
    height: 100%;
} 
.our-vision-section .core-value .vision-title{
    color: #EF4B36;
}
.core-value-list{
    padding-top: 31px;
}
.core-value-list li{
    padding-bottom: 22px;
}
.core-contain-img {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
}
.core-value .core-value-contain{
    text-align: left;
    font: normal normal 600 20px/32px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
    margin-left: 15px;
}
.blogs,.events{
	padding-bottom: 58px;
	padding-right: 43px;
}
.our-wrok-list li .nav-link{
    text-align: left;
    font: normal normal 15px/20px Poppins;
    letter-spacing: 0px;
    color: rgba(0, 0, 0,0.34);
    text-transform: capitalize;
    cursor: pointer;
}
.our-wrok-list li .nav-link:hover{
    color: rgba(0, 0, 0,0.34);   
}
.our-wrok-list li .nav-link.active:hover{
    color: #EF4B36;
}
.nav-pills .nav-link.active{
    background-color: transparent;
    color: #EF4B36;
}

.collapse.show{
    margin-top: 25px;
}
.contact-text-area{
    text-align: left;
    letter-spacing: 0px;
    color: #9C9696;
    padding: 16px;
    font: normal 14px/23px poppins;
    width: 100%;
    border-radius: 6px;
    border: 0;
    background-color: #FEF4F3;
}
.contact-input,
.contact-select{
    height: 60px;
    text-align: left;
    letter-spacing: 0px;
    color: #9C9696;
    padding: 16px;
    font: normal 16px/23px poppins;
    width: 100%;
    border-radius: 6px;
    border: 0;
    background-color: #FEF4F3;
}
/* ================================
            home modal
=================================== */
.home-modal_form{
    padding-left: 26px;
    padding-right: 26px;
}
.startup-modal-title{
    font: normal normal 600 30px/46px Poppins;
    letter-spacing: 0px;
    color: #EF4B36;
}
.home_form-modal .modal-body{
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 10px;
    padding: 12px 12px 30px;
}   
.startup_home-modal .modal-dialog{
    max-width: 516px;
}
.hire_home-modal  .modal-dialog{
    max-width: 750px;
}
/* ================================
            responssive
=================================== */
@media screen and (min-width:991.98px) and (max-width:1200px){
    .client-img-data .slick-list{
        height: 369px !important;
    }
}
@media screen and (max-width:1140px){
    .blogs,.events{
        padding-right: 15px;
    }
}
@media screen and (min-width:767px) and (max-width:1024px){
    .service-contain{
        padding: 30px 15px;
    }
}
@media screen and (max-width:1024px){
    .timeline-data{
        padding: 0 !important;
    }
}
@media screen and (max-width:991px){
    section{
        padding: 40px 0;
    }
    .work-details h2,.banner-title,.common-title{
        font: normal normal 600 30px/45px Poppins !important;
    }
    .pionner-imgs{
        height: auto;
    }
    .thought-title-contain{
        font: normal normal normal 17px/36px Poppins;
    }
    .expert-techno-title{
        font: normal normal 600 22px/32px Poppins !important;
    }
    .country-client{
        background-position-x: 29px !important;
    }
    /* .quote-icon{
        margin: 0 auto !important;
    } */
    .Benefits h2{
        font-size: 35px !important;
    }
    .role-title{
        font: normal normal 600 23px/40px Poppins !important;
    }
    .role-data{
        padding-bottom: 20px !important;
    }
    .ceo-contain .ceo-name{
        font: normal normal 600 25px/40px Poppins !important;
    }
    .ceo-contain .ceo-position,
    .dev-content-data .dev-title{
        font: normal normal 600 18px/33px Poppins !important;
    }
    .dev-content-data .dev-title{
        margin: 13px 0 10px;
    }
    .who-we-data{
        padding: 0 35px !important;
    }
    /* .archive-right-data{
        margin-left: 0 !important;
    } */
    .header .navbar .collapse .nav-item:first-child{
        margin-left: 7px;
    }
    .sub-menu{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
    .our-vision-section .vision-contain{
        padding-right: 0px !important;
    }
    .form-submit{
        font: normal normal 600 16px/27px Poppins;
    }
    .system-techno{
        padding-top: 40px !important; 
    }
    .ceo-contain .ceo-position{
        margin-bottom: 10px;
    }
    .sub-banner-title,.challange-title{
        font: normal normal 600 25px/35px Poppins !important;
    }
    
    
}

@media screen and (max-width:767px){
    .common-title, .simply-work-flow .work-contain,.banner-title{
        font: normal normal 600 23px/35px Poppins !important;
    }
    .thought-title-contain,.work-box-contain{
        font: normal normal normal 16px/25px Poppins !important;
    }
    .banner-contain,
    .modal-detail-subtitle,.loaction-address,.email-address{
        font: normal normal 16px/30px Poppins !important;
    }
    .quote-title,
    .work-box-sub-title{
        font: normal normal 600 18px/22px Poppins !important;
    }
    .modal-detail-title,.client-review-title{
        font: normal 600 20px/30px Poppins !important;
    }
    .client-review-title{
        margin-top: 10px;
    }
    .contact-container{
        padding: 0px 40px;
    }
    .work-details h2,.conculation-title{
        font: normal normal 600 25px/35px Poppins !important;
    }
    .challenge-task-contain{
        font: normal normal 600 18px/25px Poppins !important;
    } 
    .common-career{
        font-size: 18px !important;
        margin-top: 8px !important;
    }
    .thing2 h4,.thing3 h4,.thing4 h4,.thing h4,.card h3{
        font-size: 18px !important;
    }
    .Benefits h2{
        font-size: 25px !important;
        line-height: 30px;
    }
    .blogs,.events{
        padding-bottom: 24px;
    }
    .ceo-contain .ceo-position{
        font: normal normal 600 16px/33px Poppins !important;
        margin-bottom: 2px !important;
    }
    .ceo-contain .ceo-name{
        font: normal normal 600 21px/30px Poppins !important;
    }
    .opinions-box{
        padding: 20px;
    }
    .get-quote{
        padding: 20px 15px 20px 20px;
    }
    .common-title-contain{
        font: normal 15px/25px Poppins;
    }
    .opinions-box .opinions-box-title{
        font: normal normal 600 17px/30px Poppins;
    }
    .footer-menu-title{
        font: normal normal  15px/22px Poppins !important;
    }
    .footer-menu{
        font: normal normal normal 16px/18px Poppins !important;
    }
    .copy-right-text{
        font: normal normal normal 16px/25px Poppins !important;
    }
    section{
        padding: 30px 0 !important;
    }
    .banner-contain{
        margin: 6.5px 0 28px;
    }
    .who-we-data{
        padding: 0 20px !important;
    }
    .vision-row{
        padding-top: 20px;
    }
    .email-title{
        font: normal normal 600 18px/25px Poppins;
    }
    .contact-input,.contact-select{
        font: normal 15px/23px poppins;
    }
    .role-title{
        font: normal normal 600 20px/40px Poppins !important;
    }
    .role-contain{
        font: normal normal normal 16px/32px Poppins;
    }
    .challenge-task-title{
        font: normal normal bold 20px/33px Poppins;
    }
    .system-techno{
        padding-top: 20px !important;
    }
    .archive-title{
        font: normal normal 16px/28px Poppins;
        padding-top: 10px;
    }
    .expert-techno-title{
        font: normal normal 600 18px/28px Poppins !important;
    }
    .core-value .core-value-contain{
        font: normal normal 600 18px/32px Poppins;
    }
    .challange-contain{
        font: normal normal 15px/32px Poppins;
    }
}
@media screen and (max-width:620px){
    .quote-title{
        font: normal normal 600 15px/22px Poppins;
    }
    .quote-btn{
        font: normal normal 600 14px/25px Poppins;
        padding: 5.5px 15px;
    }
    section{
       padding: 25px 0 !important;
    }
    .footer-menu{
        font: normal normal normal 14px/18px Poppins !important;
    }
    .sub-banner-title, .challange-title, .work-details h2, .conculation-title{
        font: normal normal 600 20px/35px Poppins !important;
    }
    .list-group-item{
        font: normal normal  14px/23px Poppins;
    }
    .modal-detail-title, .client-review-title{
        font: normal 600 18px/30px Poppins !important;
    }
    .thought-title-contain, .work-box-contain{
        font: normal normal normal 14px/25px Poppins !important;
    }
    .simply-work-flow .work-contain{
        font: normal 600 19px/30px Poppins !important;
    }
    
}
@media screen and (max-width:550px){
    .choose-file{
        display: none;
    }
    .choose-file-text{
        right: 0 !important;
        left: 0;
    }
    .list-group-item{
        padding: 5px 10px;
    }
}
@media screen and (max-width:507px){
    .quote-btn{
        margin-top: 20px;
    }
    
}
@media screen and (max-width:576px){
    
    .contact-container{
        padding: 0px !important;
    }
    .quote-icon{
        margin: 0 auto !important;
    }
}

.contactError{
    color: red;
    font-size: 10px;
    padding-left: 16px;
}
.swal-button{
    background-color: #EF4B36;
}
.swal-button:not([disabled]):hover {
    background-color: #fff;
    color: #EF4B36;
    border: 1px solid #EF4B36
}