.work h5 {
	font-size: 16px;
	font-weight: 700;
	color: #EF4B36;
	opacity: 0.44;
	letter-spacing: 1.22px;
}
.thing {
	padding: 25px 20px 15px 20px;
	box-shadow: 0px 0px 12px #00000029;
	margin-top: 20px;
	border-radius: 8px;
	border-left: 7px solid #7142F8;
	display: inline-flex;
}
.thing2 {
	padding: 25px 20px 15px 20px;
	box-shadow: 0px 0px 12px #00000029;
	margin-top: 20px;
	border-radius: 8px;
	border-left: 7px solid #FD71AF;
	display: inline-flex;
}
.thigns-work-title{
	text-align: left;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 1.12px;
	color: #EF4B36;
	text-transform: uppercase;
	opacity: 0.44;
}

.thing3 {
	padding: 25px 20px 15px 20px;
	box-shadow: 0px 0px 12px #00000029;
	margin-top: 20px;
	border-radius: 8px;
	border-left: 7px solid #49CCF9;
	display: inline-flex;
}

.thing4 {
	padding: 25px 20px 15px 20px;
	box-shadow: 0px 0px 12px #00000029;
	margin-top: 20px;
	border-radius: 8px;
	border-left: 7px solid #FFC800;
	display: inline-flex;
}

.thing h3 {
	font-size: 24px;
	color: #7B68EE;
	font-weight: 700;
}

.thing2 h3 {
	font-size: 24px;
	color: #FD71AF;
	font-weight: 700;
}

.thing3 h3 {
	font-size: 24px;
	color: #49CCF9;
	font-weight: 700;
}

.thing4 h3 {
	font-size: 24px;
	color: #FFC800;
	font-weight: 700;
}

/* .thing h4 {
	font-size: 22px;
	font-weight: 600;
	padding-left: 40px;
} */

.thing2 h4,.thing3 h4,.thing4 h4,.thing h4 {
	font-size: 22px;
	font-weight: 600;
	padding-left: 40px;
}

/* .thing3 h4 {
	font-size: 22px;
	font-weight: 600;
	padding-left: 40px;
}

.thing4 h4 {
	font-size: 22px;
	font-weight: 600;
	padding-left: 40px;
} */

.work-details-title {
	font-size: 16px;
	font-weight: 700;
	color: #EF4B36;
	letter-spacing: 1.22px;
}

.work-details h2 {
	text-align: left;
    font: normal normal 600 36px/53px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 30px;
    margin-top: 20px;
    position: relative;
}
.work-details h2::after{
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background: #EF4B36 0% 0% no-repeat padding-box;
    border-radius: 8px;
    left: 0;
    bottom: -10px;
}
.work-details hr {
	width: 100px;
	height: 5px;
	position: absolute;
	background-color: #EF4B36;
	margin-top: 5px;
}
.vacancies-title-contain{
    font: normal normal normal 20px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 0.5;
    padding-top: 10px;
}
.who-title{
    text-align: left;
    font: normal normal bold 16px/23px Poppins;
    letter-spacing: 1.12px;
    color: #EF4B36;
    text-transform: uppercase;
    opacity: 1;
}
.work-details p {
	text-align: left;
    font: normal normal normal 18px/32px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
}

.vacancies h4 {
	font-size: 16px;
	font-weight: 700;
	color: #EF4B36;
	margin-top: 45px;
	text-align: center;
	letter-spacing: 1.44px;
}

.vacancies h2 {
	font-size: 40px;
	font-weight: 600;
	padding-top: 20px;
	text-align: center;
}

.vacancies p {
	font-size: 20px;
	opacity: 50%;
	text-align: center;
	padding-top: 5px;
}

.design-one h1 {
	background: linear-gradient(180deg, #9F8FFF 0%, #7B68EE 100%);
}



.design {
	padding: 0px 0px 0px 35px;
	margin-left: auto;
	margin-right: auto;
}



.design-timeline::after {
	content: '';
    position: absolute;
    width: 2px;
    background-color: #0000001F;
    top: 62px;
    bottom: 34px;
}

@media screen and (max-width:374px){
	.design-timeline::after{
		bottom: 48px;
	}
}

/* .design-timeline-cards {
	overflow: hidden;
} */

.design-timeline-card {
	position: relative;
    margin-left: 18px;
    padding: 18px 20px;
	box-shadow: 0px 0px 12px #00000029;
	margin-top: 20px;
	border-radius: 8px;
	border-left: 7px solid #7142F8;
	border-bottom: none;
	border-top: none;
    border-right: none;
    flex-direction: row;
    justify-content: space-between;
}

.design-timeline-card::before {
	content: "";
    width: 22px;
    height: 2px;
    background-color: #0000001F;
    position: absolute;
    top: 30px;
    left: -22px;
    z-index: -1;
}
.card h3 {
	font-size: 22px;
	font-weight: 600;
	margin-top: 5px;
	float: left;
	/* padding-right: 195px; */
}

.card .button {
	background-color: #7B68EE;
	border: none;
	color: white !important;
	font-weight: 500;
	padding: 8px 24px;
	text-align: center;
	text-decoration: none;
	font-size: 14px;
	border-radius: 4px;
	float: right;
	cursor: pointer;
}
.card2 {
	border-left: 7px solid #FFCB0E;
}
.card2 .button {
	background-color: #FFCB0E;
}
.engineering h1 {
	background: linear-gradient(180deg, #FFDB58 0%, #FFC800 100%);
}
.card3 {
	border-left: 7px solid #54CFFA;
}
.card3 .button {
	background-color: #54CFFA;
}
.development h1 {
	background: linear-gradient(180deg, #84DFFF 0%, #49CCF9 100%);
}
.common-career{
	font-size: 26px;
	font-weight: 600;
	padding: 18px 80px;
	display: inline-flex;
	color: #ffffff;
	border-radius: 8px;
	margin-bottom: 0;
}

.card4 {
	border-left: 7px solid #EF4B36;
}

.card4 .button {
	background-color: #EF4B36;
}

.sales h1 {
	background: linear-gradient(180deg, #F8796C 0%, #EC4C3D 100%);
}


.Benefits h4 {
	font-size: 16px;
	font-weight: 700;
	color: #EF4B36;
	/* margin-top: 45px; */
	letter-spacing: 1.44px;
}

.Benefits h2 {
	font-size: 40px;
	font-weight: 600;
	padding-top: 20px;
}

.Benefit {
	width: 54px;
	height: 54px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #EF4B36;
	box-shadow: 0px 10px 16px #EF4B362E;
}
.Benefit img{
	width: 45px;
	padding-left: 4px;
}

.Benefits h3 {
	font-size: 20px;
	font-weight: 600;
	padding-top: 14px;
}